@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,700i);
@import url(https://fonts.googleapis.com/css?family=Baloo+Bhaijaan);
@media (max-width: 767px) {
    body {
        padding-top: 50px;
    }
}
body {
    background-color: #f6f7f8;
}
small.extra {
        font-size:0.5em;
}

.login-div {
    margin: 10px auto 30px;
    max-width: 38%;
    min-width: 500px;
    font-family: 'Open Sans', sans-serif;
    padding: 50px 70px 70px 71px;
}

h1 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.6em;
    font-weight: bold;
}


h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.4em;
}

.glyphicon {
    color: #c7accc;
}
.react-grid-Container {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color:white;
    z-index:2;
    border-radius:0;
}
label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

.btn {
    border-radius: 17px;
    font-weight: 600;
    font-size: 0.9em;
    font-family: 'Open Sans', sans-serif;
    border: none;
}


.btn-primary {
    background: #815789;
}

.btn-info {
    background: #518959;
}


 

.help-block {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.7em;
}

.has-feedback .form-control{
    padding-right:0;
}
    
.navbar li .glyphicon {
    margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
    background-image: none;
    background-color: #815789;
    color: white;
}

@media (min-width: 768px) {
    /* On large screens, convert the nav menu to a vertical sidebar */

    .navbar-inverse .navbar-brand {
        color: #fff;
        font-family: 'Baloo Bhaijaan', cursive;
        text-transform:uppercase;
    }
    .navbar {
        height: 100%;
        font-size: 90%;
        background: #3a295c;
        width: calc(25% - 20px);
    }
    .navbar {
        border-radius: 0;
        border-width: 0;
        height: 100%;
    }
    .navbar-header {
        float: none;
    }
    .navbar .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0;
    }
    .navbar .container-fluid {
        padding: 0;
        margin: 0;
    }
    .navbar .container-fluid .navbar-brand {
        margin: 0;
    }
    .navbar ul {
        float: none;
    }
    .navbar li {
        float: none;
        font-size: 12px;
        margin: 6px;
    }
        .navbar li a {
            padding: 2px 8px;
            border-radius: 0px;
        }

    .navbar-inverse .navbar-nav > li > a {
        color: #9e92d0;
        font-family: 'Open Sans', sans-serif;
    }
    .navbar a {
        /* If a menu item's text is too long, truncate it */
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1200px) {
    /* On large screens, convert the nav menu to a vertical sidebar */
    .navbar {
        height: 100%;
        width: calc(16% - 20px);
    }
}


.ClientsDashboardPanel
{


}

@media (min-width: 768px) {
 

    .ClientsDashboardPanel .title {
        font-size: 14px;
        text-align: right;
        color: #888;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
    }

    .ClientsDashboardPanel .value {
        font-size: 24px;
        text-align: right;
        font-weight: 400;
        font-family: 'Noto Sans', sans-serif;
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.clientRow {
    border-radius: 6px 0 0 6px;
    border: 1px solid #dedede;
    padding: 4px;
    margin-bottom: 3px;
    background: white;
    font-family: 'Open Sans', sans-serif;
    -webkit-animation: 1s ease-out 0s 1 fadeIn;
            animation: 1s ease-out 0s 1 fadeIn;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}
    .clientRow.header {
        padding: 1px;
        vertical-align: text-bottom;
        min-height: 40px;
        padding-top: 12px;
        font-weight: 600;
        border-left: 6px thin #ddd;
        font-family: 'Noto Sans', sans-serif;
    }
    .clientRow.warn {
        border-left: 6px solid #ff6a00;
    }

div.good {
    font-family: 'Noto Sans', sans-serif;
    color: forestgreen;
    font-size: 18px;
    margin-top:12px;
    font-weight:600;
    margin-bottom:12px;
}

div.bad {
    font-family: 'Noto Sans', sans-serif;
    margin: 0 12px 0 12px;
    color: indianred;
    font-weight: 600;
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
}
