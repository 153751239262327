@keyframes fadeIn {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.clientRow {
    border-radius: 6px 0 0 6px;
    border: 1px solid #dedede;
    padding: 4px;
    margin-bottom: 3px;
    background: white;
    font-family: 'Open Sans', sans-serif;
    animation: 1s ease-out 0s 1 fadeIn;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}
    .clientRow.header {
        padding: 1px;
        vertical-align: text-bottom;
        min-height: 40px;
        padding-top: 12px;
        font-weight: 600;
        border-left: 6px thin #ddd;
        font-family: 'Noto Sans', sans-serif;
    }
    .clientRow.warn {
        border-left: 6px solid #ff6a00;
    }

div.good {
    font-family: 'Noto Sans', sans-serif;
    color: forestgreen;
    font-size: 18px;
    margin-top:12px;
    font-weight:600;
    margin-bottom:12px;
}

div.bad {
    font-family: 'Noto Sans', sans-serif;
    margin: 0 12px 0 12px;
    color: indianred;
    font-weight: 600;
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
}