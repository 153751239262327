
.ClientsDashboardPanel
{


}

@media (min-width: 768px) {
 

    .ClientsDashboardPanel .title {
        font-size: 14px;
        text-align: right;
        color: #888;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
    }

    .ClientsDashboardPanel .value {
        font-size: 24px;
        text-align: right;
        font-weight: 400;
        font-family: 'Noto Sans', sans-serif;
    }
}