@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,600,700');

@media (max-width: 767px) {
    body {
        padding-top: 50px;
    }
}
body {
    background-color: #f6f7f8;
}
small.extra {
        font-size:0.5em;
}

.login-div {
    margin: 10px auto 30px;
    max-width: 38%;
    min-width: 500px;
    font-family: 'Open Sans', sans-serif;
    padding: 50px 70px 70px 71px;
}

h1 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.6em;
    font-weight: bold;
}


h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.4em;
}

.glyphicon {
    color: #c7accc;
}
.react-grid-Container {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color:white;
    z-index:2;
    border-radius:0;
}
label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

.btn {
    border-radius: 17px;
    font-weight: 600;
    font-size: 0.9em;
    font-family: 'Open Sans', sans-serif;
    border: none;
}


.btn-primary {
    background: #815789;
}

.btn-info {
    background: #518959;
}


 

.help-block {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.7em;
}

.has-feedback .form-control{
    padding-right:0;
}
    